<template>
  <!-- Body -->
  <div class="sidebar__list pos-r overflow-h">
    <BaseLoad v-if="isLoad" class="grid jc-c z4 bg white-transparent" />
    <button
      v-if="isNotBottom"
      type="button"
      class="sidebar__gobottom btn outline rounded cube-30 pos-a z-10"
      @click="gotoBottom()"
    >
      <BaseIcon class="ic-16 black" icon="arrow-down" />
    </button>

    <!-- List chat -->
    <div class="overflow-a flex fd-c h-100" id="scrolled">
      <template v-if="rows && rows.length">
        <BaseLoad v-if="isLoadNext" class="grid jc-c rel md pt-20 pb-20" />
        <Item v-for="item of rows" :key="item?.id" :item="item" :order="order" />
      </template>
      <div v-else class="p-20">
        <BaseAlert type="yellow"> Ничего не найдено. </BaseAlert>
      </div>
    </div>
  </div>

  <!-- Foot -->
  <Form @updateRows="getRowsBackground" />
</template>

<script setup>
import { debounce } from 'lodash'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineProps, toRefs, ref, computed, watch, onMounted, nextTick, defineExpose } from 'vue'
import { BaseIcon, BaseLoad, BaseAlert } from '@/components'
import { imagesClick } from '@/plugins'
import socketConnect from '@/plugins/socket-connect'
import Item from './Item.vue'
import Form from './Form.vue'
import DOMPurify from 'dompurify'

// Props
const props = defineProps(['isHasEdit', 'isAll', 'currentFilter', 'searchText', 'order'])

// Data
const { currentFilter, searchText, order } = toRefs(props)
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isLoadNext = ref(false)
const isNotBottom = ref(false)
const isNotSending = ref(true)
const currentScroll = ref(0)

// Computed
const rows = computed(() =>
  store.getters['events/rows'].map((el) => {
    return {
      ...el,
      commentary: DOMPurify.sanitize(el?.commentary)
    }
  })
)
const pages = computed(() => store.getters['events/pages'])

// Watch
watch(
  searchText,
  debounce(() => {
    getRows()
  }, 1000)
)
watch(currentFilter, () => getRows())

// Created
getRows()
wsConnect()

// Mounted
onMounted(() => {
  const el = document.getElementById('scrolled')
  el.addEventListener('scroll', function () {
    isNotBottom.value = el.scrollTop < el.scrollHeight - 1000
    if (el.scrollTop < 200) getNextRows(el.scrollHeight)
  })
})

// Methods
async function getRowsBackground() {
  if (route.params.id) {
    gotoBottom('auto')
    const payload = {
      id: route.params.id,
      searchText: searchText.value,
      types: currentFilter.value
    }
    await store.dispatch('events/GET_ROWS', payload)
    await nextTick()
    gotoBottom('auto')
  }
}
async function getRows() {
  isLoad.value = true
  const payload = {
    id: route.params.id,
    searchText: searchText.value,
    types: currentFilter.value
  }
  await store.dispatch('events/GET_ROWS', payload)
  await nextTick()
  gotoBottom('auto')
  imagesClick()
  isLoad.value = false
}
async function getNextRows(scrollHeight, hasLoad = false) {
  if (isNotSending.value) {
    currentScroll.value = scrollHeight

    if (pages.value?.current_page < pages.value?.last_page) {
      isLoad.value = hasLoad
      isNotSending.value = false
      isLoadNext.value = true
      const payload = {
        id: route.params.id,
        searchText: searchText.value,
        types: currentFilter.value
      }

      await store.dispatch('events/GET_ROWS', payload)
      await nextTick()

      const el = document.getElementById('scrolled')
      el.scrollTo({
        top: el.scrollHeight - currentScroll.value - 80,
        behavior: 'auto'
      })
      imagesClick()

      isNotSending.value = true
      isLoadNext.value = false

      if (pages.value.current_page < pages.value.last_page && !rows.value?.length) getNextRows(0, true)
      else isLoad.value = false
    }
  }
}
function gotoBottom(animate = 'smooth') {
  const el = document.getElementById('scrolled')
  if (el) {
    el.scrollTo({
      top: el.scrollHeight,
      behavior: animate
    })
  }
}

// Methods:websocket
function wsConnect() {
  socketConnect
    .channel(`financier.application.history.${route.params.id}`)
    .listen('.FinancierApplicationHistoryChangedBroadcast', () => {
      getRowsBackground()
    })
}

// Expose
defineExpose({
  getRows
})
</script>
