<template>
  <div v-if="row && row.key" class="item-changes grid ggap-10 p-10">
    <small class="t-grey-dark fs-12">
      {{ fieldName }}
    </small>

    <!-- Statuses -->
    <div v-if="row.key === 'status_id'" class="flex ai-c ggap-10 fw">
      <span>
        <small
          v-if="row.value.old"
          :style="{
            color: row.value.old.text_color,
            backgroundColor: row.value.old.background_color
          }"
          class="marker cut"
        >
          {{ row.value.old.name }}
        </small>
        <template v-else>-</template>
      </span>
      <span class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span>
        <small
          v-if="row.value.new"
          class="marker cut"
          :style="{ color: row.value.new?.text_color, backgroundColor: row.value.new?.background_color }"
        >
          {{ row.value.new.name }}
        </small>
        <template v-else>-</template>
      </span>
    </div>

    <!-- Documents -->
    <div v-else-if="row.key === 'documents'" class="grid ggap-10" :class="row.value.old ? 'item-changes__gtc' : ''">
      <div v-if="row.value && row.value.deleted && row.value.deleted.length" class="box pos-r">
        <div class="item-changes__icon red" v-tippy="'Удалено'">
          <BaseIcon class="ic-14 white" icon="minus" />
        </div>
        <ItemFile
          class="border-inside"
          v-for="file of row.value.deleted"
          :key="file.id"
          :item="file"
          :btns="{ download: true, select: false, remove: false }"
        />
      </div>
      <div v-if="row.value && row.value.added && row.value.added.length" class="box pos-r">
        <div class="item-changes__icon green" v-tippy="'Добавлено'">
          <BaseIcon class="ic-14 white" icon="plus" />
        </div>
        <ItemFile
          class="border-inside"
          v-for="file of row.value.added"
          :key="file.id"
          :item="file"
          :btns="{ download: true, select: false, remove: false }"
        />
      </div>
    </div>

    <!-- Comment & Special conditions -->
    <div
      v-else-if="row.key === 'contract_exists'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? (row.value.old === 1 ? 'Есть' : 'Отсутствует') : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? (row.value.new === 1 ? 'Есть' : 'Отсутствует') : '-' }}
      </span>
    </div>

    <!-- Comment & Special conditions -->
    <div v-else-if="row.key === 'commentary' || row.key === 'special_conditions'" class="grid ggap-10">
      <span v-if="row.value.old" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.old)" />
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-down" />
      </span>
      <span v-if="row.value.new" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.new)" />
    </div>

    <!-- pay_date_planned -->
    <div v-else-if="['pay_date_planned', 'pay_date'].includes(row.key)" class="grid ggap-10">
      <span v-if="row.value.old" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.old)" />
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-down" />
      </span>
      <span class="flex fw ggap-10 editor-data">{{
        row.value.new ? moment(row.value.new).format('DD-MM-YYYY') : '-'
      }}</span>
    </div>

    <!-- Cost -->
    <div v-else-if="row.key === 'cost'" class="grid ggap-10" :class="row.value.old ? 'item-changes__gtc' : ''">
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? Number(row.value.old) : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? Number(row.value.new) : '-' }}
      </span>
    </div>

    <!-- counterparty_account_id -->
    <div
      v-else-if="row.key === 'counterparty_account_id'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? row.value?.old : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? row.value.new : '-' }}
      </span>
    </div>

    <!-- payment_ground_id -->
    <div
      v-else-if="row.key === 'payment_ground_id'"
      class="grid ggap-10"
      :class="row.value.old ? 'item-changes__gtc' : ''"
    >
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? row.value?.old?.number : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? row.value.new?.number : '-' }}
      </span>
    </div>

    <!-- Other -->
    <div v-else class="grid ggap-10" :class="row.value.old ? 'item-changes__gtc' : ''">
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? row.value.old : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? row.value.new : '-' }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, toRefs } from 'vue'
import { BaseIcon, ItemFile } from '@/components'
import DOMPurify from 'dompurify'
import moment from 'moment'

// Props
const props = defineProps(['row'])

// Data
const { row } = toRefs(props)

// Computed
const fieldName = computed(() => {
  switch (row.value.key) {
    case 'status':
      return 'Статус'
    case 'pay_date':
      return 'Дата оплаты'
    case 'comments':
      return 'Комментарий'
    case 'pay_date_planned':
      return 'План. дата оплаты'
    case 'pay_for':
      return 'За что платим'
    case 'status_id':
      return 'Статус'
    case 'counterparty_id':
      return 'Контрагент'
    case 'payment_ground_number':
      return 'Счёт на оплату'
    case 'counterparty_account_id':
      return 'Номер расчетного счёта (ИИК)'
    case 'payment_ground_date_to':
      return 'Дата счёта'
    case 'contract_exists':
      return 'Наличие договора'
    case 'cost':
      return 'Сумма'
    case 'commentary':
      return 'Комментарий'
    case 'payment_ground_id':
      return 'Счёт на оплату'
    default:
      return row.value.key
  }
})
</script>

<style lang="scss" scoped>
.item-changes {
  border-top: 1px var(--bcolor) dashed;

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -3px;
    top: -3px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.green {
      background-color: var(--green);
    }
    &.red {
      background-color: var(--red);
    }
  }
  &__gtc {
    grid-template-columns: 1fr auto 1fr;
  }
}
</style>
